import React from 'react';
import { SzTitle, SzTypographie } from '@suezenv/react-theme-components';

export default function PartA() {
  return (
    <article>
      <Intro />
      <Definition />
      <Objet />
      <ParticularCondition />
      <UpdateUseCondition />
      <WebsiteContent />
      <PersonnalData />
      <Confidentiality />
      <CookiesIpAddress />
      <HyperTextLink />
      <IntellectualPropertyRights />
      <Brand />
      <Responsability />
      <ApplicableLaw />
    </article>
  );
}

function Intro() {
  return (
    <>
      <div className="cgu-title">
        <SzTitle variant="h1">
          A. Conditions générales d'utilisation
        </SzTitle>
      </div>
      <SzTypographie>
        L'accès au Site implique le respect des présentes Conditions Générales
        d'Utilisation, en vigueur à la date de la consultation, que
        l'Utilisateur reconnaît avoir lues, comprises et acceptées.
      </SzTypographie>
      <SzTypographie>
        L'accès et l'utilisation du Site sont réservés à un usage strictement
        personnel et non commercial.
      </SzTypographie>
    </>

  );
}

function Definition() {
  return (
    <>
      <SzTitle variant="h2">1.Définitions</SzTitle>
      <SzTypographie>
        Dans les présentes Conditions Générales d'Utilisation et dans tout
        document les complétant, chaque terme mentionné ci-après a la
        signification donnée dans sa définition.
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Conditions Générales d'Utilisation » :
        </SzTypographie>
        désigne le présent document;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Editeur » :
        </SzTypographie>
        personne identifiée dans les Mentions Légales;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Site » :
        </SzTypographie>
        désigne l'ensemble des informations et services fournis aux
        Utilisateurs, accessibles par le biais du portail;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Utilisateur » :
        </SzTypographie>
        désigne toute personne physique accédant au Site et/ou utilisant un ou
        plusieurs services;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Adresse IP » :
        </SzTypographie>
        désigne un nombre automatiquement assigné à l'ordinateur de
        l'Utilisateur par son fournisseur d'accès à internet lorsqu'il navigue
        sur internet;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Cookie » ou « Témoin de connexion » :
        </SzTypographie>
        désigne un petit fichier s'installant automatiquement sur le logiciel de
        navigation de l'Utilisateur et permettant au Site d'enregistrer des
        informations de navigation;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Contenu » :
        </SzTypographie>
        désigne l'ensemble des données, et plus généralement des informations
        diffusées par l’Editeur;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Lien » ou « Lien Hypertexte » :
        </SzTypographie>
        désigne un mécanisme de référence localisé dans un produit par un
        contenu permettant d'accéder directement à un autre contenu quelle que
        soit sa localisation au sein du réseau internet;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « RGPD » :
        </SzTypographie>
        Règlement Général sur la Protection des Données;
      </SzTypographie>
      <SzTypographie>
        <SzTypographie weight="medium" tag="span">
          « Donnée Personnelle » :
        </SzTypographie>
        Toute information permettant d’identifier directement ou indirectement
        une personne physique.
      </SzTypographie>
    </>
  );
}

function Objet() {
  return (
    <>
      <SzTitle variant="h2">2. Objet</SzTitle>
      <SzTypographie>
        Le présent document a pour objet de définir les modalités d'utilisation
        du Site par l'Utilisateur et de mise à disposition des Informations et
        Services présents sur le Site.
      </SzTypographie>
    </>
  );
}

function ParticularCondition() {
  return (
    <div>
      <SzTitle variant="h2">3. Conditions Particulières</SzTitle>
      <SzTypographie>
        Certains services peuvent avoir des Conditions d'Utilisation propres («
        Conditions Particulières ») qui complètent et font partie intégrante des
        présentes Conditions d'utilisation.En cas de contradiction des
        Conditions Particulières avec les termes des Conditions Générales
        d'Utilisation, ces dernières prévaudront.
      </SzTypographie>
    </div>
  );
}

function UpdateUseCondition() {
  return (
    <div>
      <SzTitle variant="h2">
        4. Modification des Conditions d'Utilisation
      </SzTitle>
      <SzTypographie>
        La date d'entrée en vigueur des nouvelles Conditions Générales
        d'Utilisation correspond à leur date de mise en ligne, l'Utilisateur
        étant réputé accepter la dernière version en vigueur à chaque nouvel
        accès au Site.
      </SzTypographie>
    </div>
  );
}

function WebsiteContent() {
  return (
    <>
      <SzTitle variant="h2">5. Contenu du Site</SzTitle>
      <SzTypographie>
        L'ensemble des Informations disponibles sur le Site sont fournies à
        titre informatif et non contractuel.
      </SzTypographie>
      <SzTypographie>
        L’Editeur met tout en œuvre pour offrir à l'Utilisateur des Informations
        disponibles et vérifiées ou des Services accessibles, mais ne saurait
        garantir l'exactitude, l'exhaustivité ou l'actualité des Informations
        consultables sur le Site, ni la disponibilité de ces dernières ou des
        Services.
      </SzTypographie>
    </>
  );
}

function PersonnalData() {
  const data_protection_link =
    'http://www.suez.com/-/media/SUEZ-GLOBAL/Files/Publication-Docs/PDF-Francais/SUEZ-Politique-Protection-Donnees-Personnelles-mai2018-FR.pdf';
  return (
    <div>
      <SzTitle variant="h2">6. Données Personnelles</SzTitle>
      <SzTitle variant="h3">6.1 Base légale du traitement.</SzTitle>
      <SzTypographie>
        Nous collectons directement auprès de nos clients et utilisateurs du
        site ou indirectement auprès de la collectivité leurs données
        personnelles nécessaires à l’exécution de leur contrat d’abonnement au
        service d’eau et/ou assainissement dans le cadre du contrat de
        délégation du service public signé avec la collectivité.
      </SzTypographie>
      <SzTypographie>
        Vous pouvez consulter notre politique de protection des données
        personnelles mise à jour en cliquant sur
        <SzTypographie weight="bold">
          <a href={data_protection_link}>ce lien</a>.
        </SzTypographie>
      </SzTypographie>
      <SzTitle variant="h3">6.2 Catégories de données.</SzTitle>
      <SzTypographie>
        Les catégories de données susceptibles d’être collectées et traitées
        sont les données relatives à l’identité et aux coordonnées de
        l’utilisateur, aux moyens de paiement, à l’abonnement et à la
        facturation.
      </SzTypographie>
      <SzTitle variant="h3">
        6.3 Finalités et destinataires.
      </SzTitle>
      <SzTypographie>
        Ces données sont destinées à la gestion du contrat d’abonnement par :
      </SzTypographie>
      <ul className="font-family-regular">
        <li>
          nos équipes de relation client, administratives et techniques afin de
          gérer le contrat (départ, arrivée, alertes, facturation, recouvrement
          et interventions) et d’assurer le suivi de la relation client
          (demandes, communication, enquêtes de satisfaction);
        </li>
        <li>
          nos sous-traitants pour la réalisation de ces mêmes services de
          gestion du contrat d’abonnement, sous-traitants situés en France ou à
          l’étranger; dans ce dernier cas, les données font l’objet d’un
          transfert vers des pays tiers faisant l’objet d’une protection
          adéquate (la liste de ces pays est disponible sur le site web de la
          commission européenne) ; concernant les autres pays, nous nous
          engageons à signer des clauses contractuelles types pour garantir un
          niveau de protection suffisant;
        </li>
        <li>
          les organismes et auxiliaires de justice dans le cadre de leur mission
          de recouvrement des créances ainsi que les services sociaux, dans la
          limite des obligations réglementaires.
        </li>
      </ul>
      <SzTypographie>
        Dans le cas où l’utilisateur dispose d’un compteur équipé d’un
        dispositif de relève à distance, ses données de consommation sont
        relevées par ce compteur dans le cadre de l’exécution du contrat de
        fourniture d’eau, pour permettre sa facturation et l’alerter en cas de
        surconsommation et suspicion de fuite. Ce compteur communicant collecte
        un index de consommation journalier destiné exclusivement à l’Exploitant
        du service. Le client peut le consulter sur son compte en ligne sur une
        durée de 2 années glissantes, pour suivre sa consommation au jour le
        jour.
      </SzTypographie>
      <SzTypographie>
        Les données personnelles ne sont transmises à la collectivité signataire
        du contrat de délégation de service public d’eau et/ou d’assainissement
        qu’à des fins conformes à la réglementation en vigueur (transfert de
        contrat à un autre délégataire par exemple).
      </SzTypographie>
      <SzTypographie>
        Les données personnelles ne sont transmises à la collectivité signataire
        du contrat de délégation de service public d’eau et/ou d’assainissement
        qu’à son échéance, conformément au Code général des collectivités
        territoriales ou qu’à des fins conformes à la réglementation en vigueur.
      </SzTypographie>
      <SzTypographie>
        De même, lorsque les services d’eau et d’assainissement sont assurés par
        deux exploitants différents, les données personnelles recueillies par
        l’un des exploitants ne peuvent être transmises à l’autre exploitant
        qu’aux fins de l’exécution des contrats de délégation de service public.
      </SzTypographie>
      <SzTitle variant="h3">6.4 Durée de conservation.</SzTitle>
      <SzTypographie>
        Elles sont conservées pendant toute la durée du contrat d’abonnement
        prolongée des délais de prescription légale.
      </SzTypographie>
      <SzTitle variant="h3">
        6.5 Modalités d’exercice de vos droits.
      </SzTitle>
      <SzTypographie>
        Conformément à la réglementation applicable en matière de données à
        caractère personnel, le client ou l’utilisateur du site dispose d’un
        droit d’accès, de rectification, d’opposition, de limitation du
        traitement, d’effacement et de portabilité de ses données qu’il peut
        exercer :
      </SzTypographie>
      <ul className="font-family-regular">
        <li>par connexion au Compte en Ligne du présent site</li>
        <li>
          ou par email à l’adresse{' '}
          <a href="mailto:privacy.france@suez.com">
            <u>Privacy.france@suez.com</u>{' '}
          </a>
          ou par courrier à l’adresse du Délégué à la Protection des Données
          Personnelles - Suez Eau France SAS – Altiplano, 4 place de la pyramide, 92800 PUTEAUX en précisant ses nom, prénom, adresse et en joignant
          une copie recto-verso de sa pièce d’identité.
        </li>
      </ul>
      <SzTypographie>
        En cas de difficulté persistante malgré l’intervention du Délégué à la
        Protection des Données Personnelles, l’utilisateur peut s’adresser à la
        Commission Nationale Informatique et Libertés (CNIL).
      </SzTypographie>
      <SzTypographie>
        Par ailleurs, nous vous rappelons que lorsqu'un utilisateur communique
        ses données téléphoniques, il dispose du droit de s’inscrire sur la
        liste d’opposition au démarchage téléphonique. Pour plus de
        renseignements, il peut consulter le site
        <a href="www.bloctel.gouv.fr">www.bloctel.gouv.fr</a>.
      </SzTypographie>
    </div>
  );
}

function Confidentiality() {
  return (
    <>
      <SzTitle variant="h2">7. Confidentialité</SzTitle>
      <SzTypographie>
        En dehors des données personnelles visées ci-dessus, toute information,
        quelle qu'en soit la forme, que l'Utilisateur communiquera sur le Site
        ne sera en aucun cas tenue pour confidentielle et sera traitée comme non
        confidentielle par l’Editeur.
      </SzTypographie>
    </>
  );
}

function CookiesIpAddress() {
  return (
    <>
      <SzTitle variant="h2">8. Cookies - Adresse IP</SzTitle>
      <SzTypographie>
        L'Editeur se réserve le droit d'installer des cookies afin d'assurer au
        mieux la gestion du Site, faciliter la navigation sur le Site, établir
        des statistiques et de procéder à des analyses de fréquentation dans un
        but d'amélioration du Site.
      </SzTypographie>
      <SzTypographie>
        Les cookies ne permettent pas l'identification de
        l'Utilisateur.L'Utilisateur peut refuser ou supprimer les cookies en
        modifiant les paramètres de son navigateur.
      </SzTypographie>
      <SzTypographie>
        L'Editeur se réserve également le droit d'enregistrer l'adresse IP de
        l'Utilisateur, notamment afin de l'identifier si l’Editeur estime
        nécessaire de faire respecter ses Conditions d'Utilisation ou de
        protéger ses Services, ses Clients ou encore sur demande des autorités
        judiciaires.
      </SzTypographie>
    </>
  );
}

function HyperTextLink() {
  return (
    <>
      <SzTitle variant="h2">9. Liens hypertexte</SzTitle>
      <SzTypographie>
        Le Site peut contenir des liens vers d'autres sites lui appartenant ou
        non. Ces sites sont indépendants du Site et l’Editeur n'assume par
        conséquent aucune responsabilité quant à la disponibilité de ces sites,
        leur contenu, publicités, produits ou services, ni d'éventuelles
        collectes et transmission de données personnelles sur ou à partir de ces
        sites.
      </SzTypographie>
      <SzTypographie>
        Les liens vers ces sites ne constituent pas une quelconque approbation
        ou validation de leur contenu par l’Editeur ou ses filiales.
      </SzTypographie>
      <SzTypographie>
        L'Editeur rappelle que ces sites sont soumis à leurs propres Conditions
        d'Utilisation.
      </SzTypographie>
      <SzTypographie>
        L'Utilisateur du Site n'est pas autorisé à mettre en place un lien
        hypertexte en direction du Site sans une autorisation écrite et
        préalable de l’Editeur. Toute demande serait adressée au Directeur de
        Publication du Site.
      </SzTypographie>
    </>
  );
}

function IntellectualPropertyRights() {
  return (
    <>
      <SzTitle variant="h2">
        10. Droits de propriété intellectuelle
      </SzTitle>
      <SzTypographie>
        L'ensemble du Contenu du Site (notamment l'architecture et
        l'arborescence, les textes, logiciels, sons, vidéos, graphismes,
        photographies et dessins etc. apparaissant ou disponibles sur le Site),
        et plus généralement tous les éléments composants le Site, sont protégés
        par le droit de la Propriété Intellectuelle et sont la propriété
        exclusive de l’Editeur et/ou, le cas échéant, de ses filiales ou
        partenaires.
      </SzTypographie>
      <SzTypographie>
        A ce titre, l'Utilisateur s'engage, sans que cette liste soit
        limitative, à ne pas utiliser, reproduire, représenter, diffuser,
        adapter ou plus généralement exploiter le Contenu du Site protégé par le
        droit de la propriété intellectuelle, sans autorisation expresse de
        l’Editeur et/ou de ses filiales ou partenaires.
      </SzTypographie>
      <SzTypographie>
        Toute exploitation, représentation, reproduction, modification,
        publication, transmission, dénaturation non expressément autorisée,
        intégrale ou partielle du Site ou de son Contenu, par quelque procédé
        que ce soit, constituerait une contrefaçon sanctionnée par les articles
        L 335-2 et suivants du Code de la Propriété Intellectuelle.Crédit photos
        : Getty Images, Fotolia, Masterfile.
      </SzTypographie>
    </>
  );
}

function Brand() {
  return (
    <>
      <SzTitle variant="h2">11. Marques</SzTitle>
      <SzTypographie>
        Les marques qui apparaissent sur le Site sont des marques déposées et
        protégées appartenant à l’Editeur et/ou ses filiales ou partenaires.
        Toute reproduction ou utilisation de ces marques sans autorisation
        expresse de leur propriétaire est interdite et engagerait la
        responsabilité de l'Utilisateur au sens des articles L713-2 et L713-3 du
        Code de la Propriété Intellectuelle.
      </SzTypographie>
    </>
  );
}

function Responsability() {
  return (
    <>
      <SzTitle variant="h2">12. Responsabilité</SzTitle>
      <SzTypographie>
        Les marques qui apparaissent sur le Site sont des marques déposées et
        protégées appartenant à l’Editeur et/ou ses filiales ou partenaires.
        Toute reproduction ou utilisation de ces marques sans autorisation
        expresse de leur propriétaire est interdite et engagerait la
        responsabilité de l'Utilisateur au sens des articles L713-2 et L713-3 du
        Code de la Propriété Intellectuelle.
      </SzTypographie>
      <SzTypographie>
        L'Utilisateur déclare connaître et accepter les caractéristiques et les
        limites d'internet et reconnaît avoir connaissance du réseau internet et
        de ses performances techniques, et disposer des compétences et moyens
        nécessaires pour accéder au Site.
      </SzTypographie>
      <SzTypographie>
        L’Editeur et/ou ses filiales et partenaires ne sauraient donc être tenus
        responsables pour quelque dommage que ce soit, direct ou indirect, et
        notamment tout préjudice commercial, moral et financier, en ce compris
        toute perte de bénéfices ayant pour cause, origine ou fondement
        l'utilisation du Site ou son Contenu, suite à une connexion au Site, ni
        même en cas de modification, suspension, interruption ou cessation de
        tout ou partie du Site.
      </SzTypographie>
      <SzTypographie>
        L’Editeur et/ou ses filiales et partenaires ne pourront en aucun cas
        être tenus pour responsables de la fiabilité de la transmission des
        données, des temps d'accès, des éventuelles restrictions d'accès sur le
        réseau internet ou les réseaux qui lui sont connectés.
      </SzTypographie>
      <SzTypographie>
        La responsabilité de L’Editeur ne peut être engagée en cas
        d'interruption des réseaux d'accès au Site, d'indisponibilité totale ou
        partielle du Site, en cas d'erreur de transmission ou de problèmes liés
        à la sécurité des transmissions, en cas de défaillance du matériel ou du
        mode de réception de l'Utilisateur.
      </SzTypographie>
      <SzTypographie>
        L’Editeur peut être amené à interrompre l'accès au Site pour des raisons
        de maintenance. Cette interruption ne peut en aucun cas engager la
        responsabilité de l’Editeur et n'ouvre droit à aucune indemnité.
      </SzTypographie>
    </>
  );
}

function ApplicableLaw() {
  return (
    <>
      <SzTitle variant="h2">13. Loi applicable</SzTitle>
      <SzTypographie weight="medium">
        Les présentes Conditions Générales d'Utilisation sont soumises à la loi
        française.
      </SzTypographie>
    </>
  );
}
